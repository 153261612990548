html {
  font-size: 16px;
  overflow-x: hidden;
}

body {
  overflow: hidden;
  user-select: none;
}

.home-video {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 101;
  background: #0F3A57;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.home-video-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-video-content video {
  height: 100vh;
}

#ScrollDownButton {
  position: absolute;
  bottom: 64px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(90deg);
}

.navigation {
  width: 150px;
  left: unset !important;
  position: fixed !important;
  right: 0 !important;
  z-index: 99;
  top: 50% !important;
  transform: translateY(-50%);
}

.active-menu-item {
  color: #49BAF1 !important;
  transform: scale(1.2);
}

.timeline-cursor {
  width: 40px;
  right: 0px;
  z-index: 100;
  position: absolute;
  height: 1px;
  background: #FFFFFF66;
}

.nav-darker .timeline-cursor {
  background: #ABABAB66;
}

.nav-darker .timeline-block p {
  color: #ABABAB;
}

.nav-darker .step {
  background-color: #ABABAB4D;
}

.step {
  background-color: #FFFFFF4D;
}

.menu-item {
  transition: all .2s ease-in-out;
  z-index: 99 !important;
}

.menu-item:hover {
  transform: scale(1.2);
}

.roboto-regular {
  font-family: 'Roboto';
  font-weight: 300;
}

.roboto-medium {
  font-family: 'Roboto';
  font-weight: 400;
}

.roboto-bold {
  font-family: 'Roboto';
  font-weight: 700;
}

.roboto-flex-bold {
  font-family: 'Roboto Flex';
  font-weight: 700;
}

img {
  max-width: 100%;
}

.values p {
  font-size: 1rem;
  color: #0F3A5766;
}



.timeline-container {
  display: flex;
  flex-direction: column;
}

.timeline-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.timeline-block p {
  margin-right: 32px !important;
  margin-bottom: 0px !important;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  color: #F4F7F8;
  user-select: none;
  height: fit-content;
}

.step {
  width: 16px;
  height: 3px;
  background-color: #F4F7F8;
  margin-top: 8px;
  opacity: 0.3;
}

.active {
  opacity: 0.3;
}

#TEAM div {
  flex: 1;
}

#TEAM div img {
  width: 100%;
}

.block-name {
  font-family: 'Roboto';
  font-weight: 500;
}

.block-title {
  font-family: 'Roboto Flex';
  font-weight: 500;
  text-align: center;
}

.block-description {
  font-family: 'Roboto Flex';
  font-weight: 400;
  text-align: center;
}

@media only screen and (max-height: 480px) {
  .step {
    margin-top: 2px;
  }

  .timeline-cursor {
    top: 3.5px;
  }

}

@media only screen and (min-height: 481px) and (max-height:900px) {
  .step {
    margin-top: 4px;
  }

  .timeline-cursor {
    top: 5.5px;
  }
}

@media only screen and (min-height: 901px) {
  .step {
    margin-top: 8px;
  }

  .timeline-cursor {
    top: 9.5px;
  }
}


/* Mobile */
@media only screen and (max-width: 480px) {
 
  .home-video, #PURPOSE, #STEPS, #PHILOSOPHY, #PROCESS, #MIND, #TEAM {
    display: none !important;
  }

  .navigation-mobile {
    width: 100vw;
    height: 80px;
    background: #49BAF1;
    position: fixed;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .mobile-background {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: calc(100vh - 80px);
  }

  .mobile-background img{
    width: 200px;
  }


  .navigation-mobile-content {
    position: fixed;
    transition: .2s ease all;
    height: 100vh;
    width: 100vw;
    visibility: hidden;
    opacity: 0;
    background: #49BAF1;
    z-index: 98;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .navigation-mobile-content p {
    margin: 0;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    color: #F4F7F8;
    user-select: none;
  }

  .navigation {
    display: none;
  }

  .block-padding-top {
    padding-top: 80px;
  }

  .block-padding-left {
    padding-left: 32px;
  }

  .block-padding-bottom {
    padding-bottom: 80px;
  }

  .block-text-content {
    margin-bottom: 60px;
  }

  .block-name {
    font-size: 12px;
    margin-bottom: 30px;
    padding: 0 8px;
  }

  .block-title {
    font-size: 32px;
    letter-spacing: -0.06em;
    margin-bottom: 30px;
    padding: 0 8px;
  }

  .block-description {
    font-size: 16px;
    margin-bottom: 30px;
    padding: 0 8px;
  }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
  
  .home-video, #PURPOSE, #STEPS, #PHILOSOPHY, #PROCESS, #MIND, #TEAM {
    display: none !important;
  }

  .navigation-mobile {
    width: 100vw;
    height: 80px;
    background: #49BAF1;
    position: fixed;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navigation-mobile-content {
    position: fixed;
    transition: .2s ease all;
    height: 100vh;
    width: 100vw;
    visibility: hidden;
    opacity: 0;
    background: #49BAF1;
    z-index: 98;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .mobile-background {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: calc(100vh - 80px);
  }

  .mobile-background img{
    width: 200px;
  }

  .navigation-mobile-content p {
    margin: 0;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    color: #F4F7F8;
    user-select: none;
  }


  .navigation {
    display: none;
  }

  .block-padding-top {
    padding-top: 80px;
  }

  .block-padding-left {
    padding-left: 60px;
  }

  .block-padding-bottom {
    padding-bottom: 80px;
  }

  .block-text-content {
    margin-bottom: 60px;
  }

  .block-name {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .block-title {
    font-size: 40px;
    letter-spacing: -0.06em;
    margin-bottom: 30px;
  }

  .block-description {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {

  .navigation-mobile,
  .navigation-mobile-content {
    display: none;
  }

  .block-padding-top {
    padding-top: 100px;
  }

  .block-padding-left {
    padding-left: 75px;
  }

  .block-padding-bottom {
    padding-bottom: 100px;
  }

  .block-text-content {
    margin-bottom: 80px;
  }

  .block-name {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .block-title {
    font-size: 56px;
    line-height: 68px;
    letter-spacing: -0.06em;
    margin-bottom: 40px;
  }

  .block-description {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 40px;
  }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {

  .navigation-mobile,
  .navigation-mobile-content {
    display: none;
  }

  .block-padding-top {
    padding-top: 150px;
  }

  .block-padding-left {
    padding-left: 125px;
  }

  .block-padding-bottom {
    padding-bottom: 150px;
  }

  .block-text-content {
    margin-bottom: 120px;
  }

  .block-name {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .block-title {
    font-size: 64px;
    line-height: 76px;
    letter-spacing: -0.06em;
    margin-bottom: 35px;
  }

  .block-description {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 35px;
  }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {

  .navigation-mobile,
  .navigation-mobile-content {
    display: none;
  }

  .block-padding-top {
    padding-top: 200px;
  }

  .block-padding-left {
    padding-left: 175px;
  }

  .block-padding-bottom {
    padding-bottom: 200px;
  }

  .block-text-content {
    margin-bottom: 160px;
  }

  .block-name {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .block-title {
    font-size: 72px;
    line-height: 92px;
    letter-spacing: -0.06em;
    margin-bottom: 40px;
  }

  .block-description {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 43px;
  }

}

.nav-toggle {
  position: relative;
  padding: 10px;
  background: transparent;
  border: 1px solid transparent;
  margin: 16px;
}

.nav-toggle:focus {
  outline-width: 0;
}

.nav-toggle [class*='bar-'] {
  background: white;
  display: block;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .2s ease all;
  transition: .2s ease all;
  border-radius: 6px;
  height: 3px;
  width: 40px;
  margin-bottom: 6px;
}

.nav-toggle .bar-bot {
  margin-bottom: 0;
}

.opened .bar-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 15% 15%;
  transform-origin: 15% 15%;
}

.opened .bar-mid {
  opacity: 0;
}

.opened .bar-bot {
  -webkit-transform: rotate(45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 15% 95%;
  transform-origin: 15% 95%;
}

.mobile-menu-item {
  z-index:200;
}



.loader {
  width: 150px;
  height: 150px;
  line-height: 150px;
  margin: 100px auto;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  z-index: 0;
  text-transform: uppercase;
  color: white;
  font-family: 'Roboto';
}

.loader:before,
.loader:after {
  opacity: 0;
  box-sizing: border-box;
  content: "\0020";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 5px solid #fff;
  box-shadow: 0 0 50px #fff, inset 0 0 50px #fff;
}

.loader:after {
  z-index: 1;
  -webkit-animation: gogoloader 2s infinite 1s;
}

.loader:before {
  z-index: 2;
  -webkit-animation: gogoloader 2s infinite;
}

@-webkit-keyframes gogoloader {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}